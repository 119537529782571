<template>
  <!-- <div ref="cookieDeclarationRef"></div> -->
  <div>
    <NuxtLayout>
      <SpeedInsights />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/nuxt'
const cookieDeclarationRef = ref<HTMLElement | null>(null)
const { cookieDeclaration } = useCookiebot()
const { consentBanner } = useCookiebot({})

onMounted(() => {
  consentBanner()
})
</script>

<style lang="scss">
@import url('~/assets/css/main.css');
@import url('~/assets/css/all.css');
@import url('~/assets/css/font.css');
@import url('~/assets/css/satoshi.css');

/* Mobile devices */
@media (max-width: 768px) {
  body.hidden {
    overflow: hidden;
  }
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: var(--md-font);
  background-color: var(--bg-color);
  font-family: var(--font-family);
  line-height: 1.5;
  font-weight: 500;
  position: relative;
  box-sizing: border-box;
}

/*
$mobile-width: 768px;
$tablet-width: 769px;
$desktop-width: 1280px;
$widescreen-width: 1281px;
$fullhd-width: 1408px;
*/
section {
  // filter: grayscale(100%);
  // &:hover {
  //   filter: grayscale(0%);
  // }
  // * {
  //   filter: grayscale(100%);
  //   &:hover {
  //     filter: grayscale(0%);
  //   }
  // }
}
.container {
  margin-left: auto;
  // * {
  //   filter: grayscale(100%);
  //   &:hover {
  //     filter: grayscale(0%);
  //   }
  // }
  margin-right: auto;
}

/* Mobile devices */
@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}
/* Tablet devices */
@media (min-width: 769px) {
  .container {
    max-width: 1120px;
  }
}
/* Desktop devices */
@media (min-width: 1024px) {
  .container {
    max-width: 1120px;
  }
}
/* Widescreen devices */
@media (min-width: 1440px) {
  .container {
    max-width: 1281px;
  }
}
</style>
